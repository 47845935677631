<template>
  <div>
    <layoutContainer class="standard-page">
      <el-container class="stand-search">
        <div class="search-header">
          <checkRadioOnlyone
            v-if="projectInfo.name && queryParams.projectId"
            title="项目"
            :contentText="projectInfo.name"
            @close="clearProjectInfo"
          >
          </checkRadioOnlyone>
          <checkRadioOnlyone
            v-if="queryParams.orderId"
            title="订单ID"
            :contentText="queryParams.orderId"
            @close="clearOrderInfo"
          >
          </checkRadioOnlyone>
          <checkRadioHoverGroup
            title="标准包"
            :list="packageList"
            :activeIndex.sync="queryParams.packageId"
            :orderId="queryParams.orderId"
            :payedOrderId="queryParams.payedOrderId"
            keyname="name"
            :flatList="flatList"
            :showCloseIcon="canCheckPackage"
            :canCheckPackage="canCheckPackage"
            @getStandardBuyListCancelPackage="getStandardBuyListCancelPackage"
          >
          </checkRadioHoverGroup>
        </div>
      </el-container>
      <el-container class="package-detail" v-if="showPackageDetail">
        <div class="package-detail-left-logo">
          <img
            v-if="buyListInfo.packageId"
            :src="buyListInfo.logoPicUrl || '/img/package-default-logo.png'"
            alt="logo"
          />
          <span v-if="buyListInfo.packageId" class="package_quantity">
            <b class="currency">￥</b>
            <span class="number">{{ setPriceNum(buyListInfo.packagePrice) }}</span>
          </span>
        </div>

        <el-main class="package-detail-main">
          <h3 v-if="buyListInfo.packageId">
            <div class="package-title">
              {{ buyListInfo.packageName }}
              <span class="min-text"> {{ buyListInfo.standardTotal }}条标准</span>
              <img
                v-if="canEditPackage"
                src="../../../public/icon/newStanard/关闭.png"
                alt="x"
                @click="getStandardBuyListCancelPackage"
              />
            </div>
            <div class="ellipsis-container">
              <span :class="showEllipsis ? 'up-ellipsis' : 'down-ellipsis'">
                {{ buyListInfo.introduction }}
              </span>

              <img
                :class="showEllipsis ? '' : 'reverse'"
                @click="showEllipsis = !showEllipsis"
                alt="x"
                src="../../../public/icon/newStanard/关闭备份4.png"
              />
            </div>
          </h3>

          <div class="edit-container edit-container1" v-if="buyListInfo.packageId">
            <packageForComponent
              v-if="buyListInfo.childPackageOption"
              v-for="(item, i) in [buyListInfo.childPackageOption]"
              :key="i"
              :info="item || {}"
              @edit="() => getStandardPackageOptionItems(item, 1)"
              :canEditPackage="canEditPackage"
            >
            </packageForComponent>

            <packageForComponent
              v-for="(item, i) in buyListInfo.classGroupOptionList"
              :key="i + 100"
              :info="item || {}"
              @edit="() => getStandardPackageOptionItems(item, 2)"
              :canEditPackage="canEditPackage"
            >
            </packageForComponent>

            <packageForComponent
              v-for="(item, i) in buyListInfo.attributeOptionList"
              :key="i + 1000"
              :info="item || {}"
              @edit="() => getStandardPackageOptionItems(item, 3)"
              :canEditPackage="canEditPackage"
            >
            </packageForComponent>
          </div>

          <div class="bottom-br" v-if="queryParams.packageId"></div>

          <div
            class="choose-standard-box"
            v-if="
              buyListInfo.chooseStandardOption &&
                buyListInfo.chooseStandardOption.items &&
                buyListInfo.chooseStandardOption.items.length
            "
          >
            <packageForChooseComponent
              class="choose-title"
              @close="() => getStandardBuyListCancelBuy()"
              :canEditPackage="canEditPackage"
            >
              <img class="min-title-logo" alt="x" src="/img/min-title-logo.png" />
              <span>选购标准</span>
            </packageForChooseComponent>
            <div class="choose-container" v-if="buyListInfo.chooseStandardOption">
              <packageForChooseComponent
                class=" choose-item-box"
                v-for="(opt, i) in buyListInfo.chooseStandardOption.items"
                :key="i"
                @close="() => getStandardBuyListCancelBuy(opt.standardId)"
                :canEditPackage="canEditPackage"
              >
                {{ opt.name }} <span class="quantity">￥{{ setPriceNum(opt.price) }}</span>
              </packageForChooseComponent>
            </div>

            <div
              class="edit-container edit-container-second"
              v-if="buyListInfo.chooseAttributeOptionList && buyListInfo.chooseAttributeOptionList.length"
            >
              <packageForComponent
                v-for="(item, i) in buyListInfo.chooseAttributeOptionList"
                :key="i"
                :info="item || {}"
                @edit="() => getStandardPackageOptionItems(item, 4)"
                :canEditPackage="canEditPackage"
              >
              </packageForComponent>
            </div>

            <div class="package-detail-explain " v-if="buyListInfo.chooseStandardOption">
              共{{ buyListInfo.chooseStandardOption.chooseStandardTotal }}条， 小计
              <span class="quantity">
                <span class="currency">￥</span>
                <span class="number">{{
                  setPriceNum(buyListInfo.chooseStandardOption.chooseStandardTotalPrice) || 0
                }}</span>
              </span>
              <span v-if="buyListInfo.chooseStandardOption.repeatStandardTotal">
                （已减去与 {{ buyListInfo.packageName }} 重复的标准{{
                  buyListInfo.chooseStandardOption.repeatStandardTotal
                }}条共
              </span>
              <span class="quantity" v-if="buyListInfo.chooseStandardOption.repeatStandardTotal">
                <span class="currency">￥</span>
                <span class="number">
                  {{ setPriceNum(buyListInfo.chooseStandardOption.repeatStandardTotalPrice) }}</span
                >
              </span>
              <span v-if="buyListInfo.chooseStandardOption.repeatStandardTotal">）</span>
            </div>
            <div class="bottom-br"></div>
          </div>

          <div class="package-detail-footer">
            <span class="empty_space"></span>
            <span class="footer-amount"
              >合计<span class="footer-quantity">
                <span class="currency">￥</span>
                <span class="number"> {{ setPriceNum(buyListInfo.totalPrice) }} </span>
              </span></span
            >
            <b class="button-pay" v-if="canEditPackage" @click="buyForOrder">支付</b>
          </div>
        </el-main>
      </el-container>

      <div class="main-tips">
        <p class="left-tips" @click="showAllStandard(true)" :class="isAllStandard ? 'active-tips' : ''">
          全部共&nbsp;{{ leftStandardTotal }}&nbsp;标准
        </p>
        <p
          class="right-tips"
          :class="!isAllStandard ? 'active-tips' : ''"
          @click="showAllStandard(false)"
          v-if="rightStandardTotal"
        >
          {{ chooseStandardOptionLength ? '选购区' : buyListInfo.packageName }}共&nbsp;{{
            rightStandardTotal
          }}&nbsp;标准
        </p>
        <div class="bottom-br"></div>
      </div>

      <el-container>
        <StandardLeftAside
          :leftList="leftList"
          :activeParentId="activeParentId"
          :activeIndex="activeIndex"
          @regetData="regetData"
        />
        <el-main class="right-main-container">
          <StandardRightAside
            v-for="(item, i) in rightList"
            :item="item"
            :key="i"
            :canEditPackage="canEditPackage && UserInfo.id"
            :canBuyButton="canShowAddBuyButton"
            @addBuyList="addBuyList"
            @showFun="showFun"
          />
          <center>
            <el-pagination
              v-if="pageInfo.total"
              background
              layout="prev, pager, next"
              :current-page.sync="pageInfo.pageNum"
              :page-size="pageInfo.pageSize"
              :total="pageInfo.total"
              :page-sizes="[10, 20, 30, 40, 50, 100]"
              @current-change="changePage"
            >
            </el-pagination>
          </center>
        </el-main>
      </el-container>
    </layoutContainer>
    <el-dialog title="达标方法" :visible.sync="dialogVisible" width="60%" :show-close="true">
      <span class="way">{{ reachMethod }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <dialogMask :show.sync="editAttrDialogVisible" :showClose="true">
      <div class="edit-attr-dialog">
        <div class="title">
          <img src="/img/dialog-title-logo.png" alt="" />
          <div class="title-desc">{{ editAttrDialogInfo.name }}</div>
        </div>
        <div class="content">
          <checkButton
            :defaultList="editAttrDialogInfo.items"
            :checkedList.sync="checkedList"
            @hoverIntroduction="hoverChangeIntroduction"
            :isMultiple="editAttrDialogInfo.hasMultiSelect"
          >
          </checkButton>
          <div class="introdution" v-if="editAttrDialogInfo.introduction">
            <img class="el-icon-warning-outline" src="/img/!!!.png" alt="!" />
            <span class="way">{{ hoverIntroductionText }}</span>
          </div>
        </div>
        <div class="footer">
          <button class="footer-button" type="primary" @click="saveCheckedList">确 定</button>
        </div>
      </div>
    </dialogMask>
    <div v-if="showPayDialog">
      <pay
        :orderCode="payOrderCode"
        :showPayDialog.sync="showPayDialog"
        :orderPrice="setPriceNum(buyListInfo.totalPrice)"
      >
      </pay>
    </div>
  </div>
</template>

<script>
const iconPicUrl = 'https://www.quanqingkeji.com/cos/download/imgDownload/202308/quanbu.png'
// 需要本地化处理结合路由驱动处理  queryParams 中的信息，以保持购物车的功能的多样性
import {
  getStandardBuyApi,
  getStandardBuyListApi,
  getStandardBuyListCancelBuyApi,
  getStandardBuyListCancelPackageApi,
  getStandardGetClassGroupApi,
  getStandardListApi,
  getStandardPackageOptionItemsApi,
  getStandardPackageOptionSaveApi,
  getStandardPackageListApi,
  getStandardPayApi
} from '../../api/standard'
import {getDescribeInfoByProjectId, getCurrentUserAuthByProjectId} from '../../api/project'
import {mapState} from 'vuex'
import packageForComponent from './components/packageForComponent.vue'
import packageForChooseComponent from './components/packageForChooseComponent.vue'
import checkRadioHoverGroup from '../../components/checkRadioHoverGroup.vue'
import checkButton from '../../components/checkButton.vue'
import dialogMask from '@/components/common/dialog.vue'
import pay from './components/pay.vue'
import {setPriceNum} from '@/utils/index'
import StandardLeftAside from './components/standardLeftAside.vue'
import StandardRightAside from './components/standardRightAside.vue'

export default {
  computed: {
    ...mapState(['SearchInput', 'UserInfo']),
    ellipsisSrc() {
      return this.showEllipsis
        ? '../../../public/icon/newStanard/关闭备份 4.png'
        : '../../../public/icon/newStanard/关闭备份 4.png'
    },
    canCheckPackage() {
      if (this.queryParams.orderId || this.queryParams.payedOrderId) return false
      if (this.queryParams.projectId && !this.isProjectOwer) return false
      return true
    },
    canEditPackage() {
      if (this.queryParams.orderId || this.queryParams.payedOrderId) return false
      if (this.queryParams.projectId && !this.isProjectOwer) return false
      return true
    },
    canShowAddBuyButton() {
      if (!this.isAllStandard) return false
      if (this.queryParams.orderId || this.queryParams.payedOrderId) return false
      if (this.queryParams.projectId && !this.isProjectOwer) return false
      return true
    },
    showPackageDetail() {
      // 0 全不显示   1,全部显示    2 只显示选购区域
      // UserInfo.id && (queryParams.packageId || (buyListInfo.chooseStandardOption
      //  && buyListInfo.chooseStandardOption.items && buyListInfo.chooseStandardOption.items.length))
      return (
        this.buyListInfo?.chooseStandardOption?.items?.length ||
        this.buyListInfo?.classGroupOptionList?.length ||
        this.buyListInfo?.attributeOptionList?.length ||
        this.buyListInfo?.childPackageOption
      )
    },
    chooseStandardOptionLength() {
      return this.buyListInfo?.chooseStandardOption?.items?.length || 0
    },
    flatList() {
      function flat(arr, depth = 1) {
        let result = []
        for (let i = 0; i < arr.length; i++) {
          result.push(arr[i])
          if (arr[i].children && arr[i].children.length && depth > 0) {
            result.push(...flat(arr[i].children, depth - 1))
          }
        }
        return result
      }
      return flat(this.packageList)
    }
  },
  components: {
    packageForComponent,
    packageForChooseComponent,
    checkRadioHoverGroup,
    checkButton,
    dialogMask,
    StandardLeftAside,
    StandardRightAside,
    pay
  },
  data() {
    return {
      iconPicUrl,
      dialogVisible: false,
      showEllipsis: true,
      activeIndex: 0,
      activeParentId: '',
      leftStandardTotal: '',
      rightStandardTotal: '',
      isAllStandard: true,
      leftList: [{id: '', name: '全部'}],
      reachMethod: '',
      rightList: [],
      packageList: [],
      queryParams: {
        packageId: '',
        orderId: '',
        payedOrderId: '',
        projectId: ''
      },
      projectInfo: {}, //项目信息
      buyListInfo: {}, //购买清单信息
      editAttrDialogInfo: {}, //编辑属性集合
      editAttrDialogVisible: false,
      checkedList: [],
      optionType: 1,
      optionId: '',
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      },
      hoverIntroductionText: '',
      payOrderCode: '',
      showPayDialog: false,
      isProjectOwer: false
    }
  },
  watch: {
    SearchInput() {
      // this.regetData(this.activeIndex)
    },
    queryParams: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.setCachePackage()
          if (!this.queryParams.packageId) {
            this.showAllStandard(true)
          }
          this.getStandardBuyList()
        })
      }
    },
    ['$route.query']: {
      immediate: true,
      deep: true,
      handler() {
        this.patchRouteParamsOrCatch()
        this.getCachePackage()
        log.JSON(this.queryParams)
        // 首页跳转时候需要添加到选购
        // if (query.packageId && !query.projectId && !query.orderCode) {
        //   this.addBuyList(query.packageId)
        // }
      }
    }
  },
  created() {
    this.patchRouteParamsOrCatch()
    this.getCachePackage()
    this.getStandardBuyList()

    getStandardPackageListApi().then(res => {
      this.packageList = res.data
    })
  },
  // patchRouteParamsOrCatch
  //  1.首页点击更多标准包跳转进来，或顶部导航跳转进来， 不带任何餐参数（展示缓存的之前的参数）（切换包会缓存）（选购区操作修改和购买需要登录校验）
  //  2.首页点击标准包跳转进来（相当于case1 + 切换到对应的标准包添加到选购区）（选购区操作修改和购买需要登录校验）
  //        带    packageId（可操作和购买）（切换包会缓存）（选购区操作修改和购买需要登录校验）
  // （**上面两种情况需要缓存 packageId选购区域）

  //  3.订单页跳转进来
  //        带    orderId（必选，仅可删除订单选项 /不可操作和购买package）  （只能查看和删除，不缓存任何参数，不影响缓存的购买区域）（任何人不能更改详细选项）

  //  4.项目详情页跳转进来（）（如果拉取的选购区包含订单信息，那么这个等同于order订单详情的效果，不能操作，只能提示）
  //        带    projectId（必选，仅可删除） （可操作和购买package）（切换包会缓存）（操作需要校验是否是运营人员，或有项目权限的人）
  //              （选购区操作修改和购买需要登录校验）

  methods: {
    setPriceNum,
    patchRouteParamsOrCatch() {
      const query = this.$route.query
      this.activeIndex = query.standard_class_id ? query.standard_class_id : 0
      this.queryParams.packageId =
        query.standard_package_id || query.packageId ? query.standard_package_id || query.packageId : ''
      this.queryParams.orderId = query.orderCode
      this.queryParams.projectId = query.projectId
      if (query.projectId) {
        getDescribeInfoByProjectId({projectId: query.projectId}).then(res => {
          log.JSON(res, '获取项目权限信息')
          this.projectInfo = res.data || {}
        })

        getCurrentUserAuthByProjectId({projectId: query.projectId}).then(res => {
          log.JSON(res, '获取本人项目权限信息')
          if (res.data.includes(1) || res.data.includes(0)) {
            this.isProjectOwer = true
          } else {
            this.isProjectOwer = false
          }
        })
      }
    },
    getCachePackage() {
      if (this.queryParams.orderId || this.queryParams.projectId || this.queryParams.packageId) return
      const standardCacheStr = localStorage.getItem('standardCache')
      const {packageId} = JSON.parse(standardCacheStr) || {}
      if (!standardCacheStr || !packageId) return
      this.queryParams.packageId = packageId
    },
    setCachePackage() {
      // 放在 watch 里面，自动缓存
      if (this.queryParams.orderId || this.queryParams.projectId) return
      localStorage.setItem('standardCache', JSON.stringify(this.queryParams))
    },
    // 获取标准分组
    getLeftList() {
      let params = {}
      if (!this.isAllStandard) {
        params = this.formatFetchParams({needBuyList: true})
      }
      getStandardGetClassGroupApi(params)
        .then(res => {
          log.info(res, '获取标准分组')
          if (this.isAllStandard) {
            this.leftStandardTotal = res.data.standardTotal
          }
          this.leftList = [{id: '', name: '全部', iconPicUrl}, ...(res.data?.groupList || [])]
          this.getRightList()
        })
        .catch(log.err('标准分类列表'))
    },
    // 获取标准列表
    getRightList() {
      let params = {...this.pageInfo}
      if (!this.isAllStandard) {
        params = this.formatFetchParams({...params, needBuyList: true})
      }
      if (this.activeParentId && this.activeIndex) {
        params.classId = this.activeIndex
      }
      getStandardListApi(params).then(res => {
        // log.JSON(res, 'getStandardListApi')
        this.rightList = [...res.data.records]
        this.pageInfo.pageNum = res.data.current || 1
        this.pageInfo.pageSize = res.data.size || 20
        this.pageInfo.total = res.data.total
      })
    },

    // 获取默认的购买订单信息
    getStandardBuyList() {
      let params = this.formatFetchParams()
      getStandardBuyListApi(params).then(res => {
        // console.log('getStandardBuyApi. 默认的购买订单信息. queryParams.', queryParams)
        // console.log('getStandardBuyApi. 默认的购买订单信息..', res, JSON.stringify(res.data, null, 2))
        this.buyListInfo = res.data
        this.queryParams.packageId = res.data.packageId
        this.queryParams.payedOrderId = res.data.projectOrderId || ''
        this.rightStandardTotal =
          res.data?.standardTotal +
          (res.data.chooseStandardOption
            ? res.data.chooseStandardOption?.chooseStandardTotal - res.data.chooseStandardOption?.repeatStandardTotal
            : 0)
        // v-if="queryParams.packageId && (buyListInfo.classGroupOptionList && buyListInfo.classGroupOptionList.length || buyListInfo.attributeOptionList && buyListInfo.attributeOptionList.length || buyListInfo.childPackageOption)">
        if (
          !this.buyListInfo?.chooseStandardOption?.items?.length ||
          (!this.buyListInfo?.classGroupOptionList?.length &&
            !this.buyListInfo?.attributeOptionList?.length &&
            !this.buyListInfo?.childPackageOption)
        ) {
          this.showAllStandard(true)
        } else {
          this.getLeftList()
        }
      })
    },
    // 移除整个标准包选项
    getStandardBuyListCancelPackage() {
      this.$confirm('确定要移除此标准包吗？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          getStandardBuyListCancelPackageApi({
            packageId: this.queryParams.packageId
          }).then(res => {
            this.queryParams.packageId = ''
            setTimeout(() => {
              this.$message({
                type: 'success',
                message: '移除成功!',
                center: true
              })
            }, 250)
            this.getStandardBuyList()
            this.showAllStandard(true)
          })
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: 'warning',
              message: '已取消移除',
              center: true
            })
          }, 250)
        })
    },

    // 获取下拉修改弹窗基础信息
    getStandardPackageOptionItems(item, optionType) {
      this.checkedList = item.items
      this.optionType = optionType
      this.optionId = item.id
      getStandardPackageOptionItemsApi({
        optionId: item.id,
        optionType,
        packageId: this.queryParams.packageId
      }).then(res => {
        this.editAttrDialogInfo = res.data
        this.hoverIntroductionText = res.data.introduction
        this.editAttrDialogVisible = true
      })
    },
    // 编辑标准下拉切换保存
    saveCheckedList() {
      if (!this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      if (this.checkedList.length == 0) {
        this.$alert('请至少选择一个选项')
        return
      }
      let params = {
        optionId: this.optionId, //包属性id
        optionItemIds: this.checkedList.map(i => i.id), //包属性条目ids
        optionType: this.optionType //包属性类型 1-细分包 2-分类分组 3-单独属性 4-选购标准单独属性
      }
      params = this.formatFetchParams(params)
      getStandardPackageOptionSaveApi(params).then(res => {
        this.editAttrDialogVisible = false
        this.getStandardBuyList()
      })
    },
    // 添加购买标准
    addBuyList(standardId) {
      if (!this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      getStandardBuyApi({
        projectId: this.queryParams.projectId, //项目id
        standardId //标准id
      })
        .then(res => {
          if (res.msg == 'success') {
            this.$message({
              message: '成功加入选购区',
              type: 'success',
              center: true
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              center: true
            })
          }
          this.getStandardBuyList()
        })
        .catch(err => {
          log.err(err, 'getStandardBuyApi.获取默认的购买订单信息')
          this.getStandardBuyList()
        })
    },
    // 取消购买标准
    getStandardBuyListCancelBuy(standardId) {
      if (!this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      const confirmText = standardId ? '确定要移除此标准吗？' : '确定要移除所有标准吗？'
      this.$confirm(confirmText, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = {
            projectId: this.queryParams.projectId
          }
          if (standardId) {
            params.standardId = standardId
          }
          getStandardBuyListCancelBuyApi(params).then(res => {
            setTimeout(() => {
              this.$message({
                type: 'success',
                message: '移除成功!',
                center: true
              })
            }, 250)
            this.getStandardBuyList()
          })
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: 'warning',
              message: '已取消移除',
              center: true
            })
          }, 250)
        })
    },
    // 获取支付订单
    buyForOrder() {
      let params = {}
      if (this.queryParams.projectId) {
        params.projectId = this.queryParams.projectId
      }
      getStandardPayApi(params).then(res => {
        log.info(res, '获取支付订单')
        // 获取到支付的订单号，开启支付流程
        this.payOrderCode = res.data
        this.showPayDialog = true
      })
    },

    showAllStandard(boll) {
      this.isAllStandard = boll
      this.resetSelectId()
      this.resetPageInfo()
      this.getLeftList()
    },
    resetPageInfo() {
      Object.assign(this.$data.pageInfo, this.$options.data().pageInfo)
    },
    resetSelectId() {
      this.activeIndex = ''
      this.activeParentId = ''
    },
    regetData(item, activeIndex, activeParentId) {
      if (!activeIndex && activeParentId) {
        return
      }
      this.activeParentId = activeParentId
      this.activeIndex = activeIndex
      this.rightList = []
      this.getRightList()
    },
    clearProjectInfo() {
      this.projectInfo = {}
      this.queryParams.projectId = ''
      this.queryParams.packageId = ''
      this.queryParams.orderId = ''
      this.queryParams.payedOrderId = ''
    },
    clearOrderInfo() {
      this.projectInfo = {}
      this.queryParams.projectId = ''
      this.queryParams.packageId = ''
      this.queryParams.orderId = ''
      this.queryParams.payedOrderId = ''
    },
    hoverChangeIntroduction(text) {
      this.hoverIntroductionText = text ? text : this.editAttrDialogInfo.introduction
    },
    // 分页切换
    changePage(val) {
      this.pageInfo.pageNum = val
      this.getRightList()
    },
    // 达标方法弹窗
    showFun(info) {
      this.reachMethod = info
      this.dialogVisible = true
    },
    formatFetchParams(query = {}) {
      const params = {...query}
      if (this.queryParams.orderId) {
        params.orderId = this.queryParams.orderId
      }
      if (this.queryParams.projectId) {
        params.projectId = this.queryParams.projectId
      }
      if (this.queryParams.packageId) {
        params.packageId = this.queryParams.packageId
      }
      return params
    }
  }
}

// optionType  包属性类型 1-细分包 2-分类分组 3-单独属性 4-选购标准单独属性
// 各种预设标准包在修改之后应该单独保存，而非继续拉去原有的预设包  添加选购和移除选购需要 projectId，这个没有项目的时候如何生成？或者这里的控制方式
//   classId 参数好像没生效
// 支付需要先获取订单号   然后在走正常支付流程

// tudo:  支付对接
// 权限按钮设置   展示 购买按钮，展示 包分类
// 添加购买按钮    路由
//                    有订单号时不展示   有项目号并且没有权限不展示  有标准包 需要展示
//                    没有登录需要展示   登录以后需要展示
//  标准包详情    里面的编辑和关闭按钮
//                    路由 有订单号时不展示   有项目号并且没有权限不展示  有标准包 需要展示
//                    没有登录需要展示   登录以后需要展示

// 做缓存区，，
//    如果路由携带  订单 id 或则 项目 id ，则不能启用缓存区，也不能刷新替换缓存区的内容
//      需要关联是否登录  用户id 是否改变，同时匹配时启用缓存区
//      路由是否是 带参数跳转（优先展示带参数的）情况，
//         缓存区需要缓存 选中的包id（选中时直接添加，或路由参数与当前筛选信息不一致时缓存）

//  *
//  * caches:{
//  *    usedId:{
//   *        projectId:
//   *        orderId:
//    *       packageId:
//    *       path:
//   *}}
//  *
//  *
</script>

<style src="./scss/newStandard.scss" lang="scss" scoped></style>
