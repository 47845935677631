<template>
  <div>
    <layoutContainer>
      <el-container>
        <el-aside class="aside" style="width:250px">
          <h4 class="title">产品分类</h4>
          <div :class="activeIndex == i ? 'active' : ''" @click="regetData(i)" v-for="(item, i) in leftList" :key="i">
            {{ item.name }}
          </div>
        </el-aside>
        <el-main>
          <el-container class="right" v-for="(item, i) in rightList" :key="i">
            <el-aside class="right-aside" width="150px">
              <h4 style="font-weight:600">{{ item.name }}</h4>
              <p v-if="item.isOnline">
                <button class="right-aside-button">产品演示</button>
              </p>
              <button v-if="item.isOnline" class="right-aside-button">购买</button>
              <button v-else disabled class="right-aside-button" style="background:#cccccc">规划中</button>
            </el-aside>
            <el-main class="right-main">
              <p v-if="item.target">
                <span>产品目标 ： </span><b>{{ item.target }}</b>
              </p>
              <p v-if="item.ability">
                <span>产品能力 ： </span><b>{{ item.ability }}</b>
              </p>
              <p v-if="item.introduction">
                <span>更多介绍 ： </span><b>{{ item.introduction }}</b>
              </p>
            </el-main>
          </el-container>
        </el-main>
      </el-container>
    </layoutContainer>
  </div>
</template>

<script>
import {getTechProductClassListApi, getTechProductListApi} from '../../api/standard'
import {mapState} from 'vuex'

export default {
  computed: {...mapState(['SearchInput'])},
  data() {
    return {
      id: null,
      activeIndex: 0,
      leftList: [
        {id: '', name: '全部'},
        {id: 1, name: '可用性', sequenceNum: 1}
      ],
      rightList: [
        {
          id: 2,
          name: '接口失败率',
          classId: 1,
          target: '持续监控页面是否正常，打不开立即报警',
          ability: '24小时不停监控客户配置的页面，最高每 5 秒调用一次，发现页面打不开，在最快10秒内报警。',
          introduction: '当页面的核心接口已监控，则页面崩溃的概率大幅度降低',
          idInProductCenter: 2,
          isOnline: false,
          sequenceNum: 2
        }
      ]
    }
  },
  watch: {
    SearchInput() {
      this.regetData(this.activeIndex)
    },
    '$route.query.classId'() {
      const id = this.$route.query.classId
      this.activeIndex = this.leftList.findIndex(item => item.id == id) || 0
      this.getTechProductList({page: 1, size: 100, techProductClassId: id})
    }
  },
  created() {
    const {classId} = this.$route.query
    this.classId = classId
    // if (this.id) {
    //   this.getTechProductList({page: 1, size: 100, techProductClassId: id})
    // }
    this.getTechProductClassList(classId ? classId : undefined)
    this.regetData(this.activeIndex)
  },
  methods: {
    regetData(i) {
      this.activeIndex = i
      this.rightList = []
      if (i == 0) {
        this.getTechProductClassList()
      } else {
        this.getTechProductList({page: 1, size: 100, techProductClassId: this.leftList[i].id})
      }
    },
    // 获取全部
    getTechProductClassList(id) {
      getTechProductClassListApi()
        .then(res => {
          this.leftList = [{id: '', name: '全部'}, ...res.data]
          if (!id) {
            this.getAllRightList(res.data)
          } else {
            this.activeIndex = this.leftList.findIndex(item => item.id == id)
            this.getTechProductList({page: 1, size: 100, techProductClassId: id})
          }
        })
        .catch(err => {
          console.log('%c 产品分类列表 err....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
        })
    },
    getAllRightList(arr) {
      Promise.all(
        arr.map(item => {
          let params = {...item, techProductClassId: item.id}
          if (this.id) {
            params.techStandardId = this.id
          }

          if (this.SearchInput) {
            params.keyWord = this.SearchInput
          }
          return getTechProductListApi(params)
        })
      ).then(res => {
        this.rightList = res.map(item => item?.data?.records || []).flat()
      })
    },
    // 获取单个分类
    getTechProductList(item) {
      let params = {...item}
      if (this.id) {
        params.techStandardId = this.id
      }
      if (this.SearchInput) {
        params.keyWord = this.SearchInput
      }
      getTechProductListApi(params)
        .then(res => {
          this.rightList = [...res.data.records]
        })
        .catch(err => {
          console.log('%c 产品标准列表 err....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
        })
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  height: 50px;
  line-height: 50px;
  background: #252525;
  color: #ffffff;
  border-radius: 4px 4px 0px 0px;
}
.aside {
  width: 250px;
  background: #ffffff;
  margin-right: 15px;
  text-align: center;
  h4 {
    margin-bottom: 20px;
  }
  > div {
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    margin-bottom: 15px;
    // background: #fef5e5;
  }
}
.right {
  background: #ffffff;
  padding: 30px;
  margin-bottom: 20px;
}
.right-aside {
  width: 150px im !important;
  text-align: center;
  margin: 0 auto;
  > h4 {
    margin-bottom: 20px;
  }
}
.right-main {
  background: #f6f6f6;
  p {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    span {
      color: #999999;
      width: 120px;
      text-align: right;
    }
    b {
      flex: 1;
    }
  }
}
.active {
  background: #fef5e5;
}

.right-aside-button {
  width: 90px;
  height: 26px;
  color: #ffffff;
  background: #ff724c;
  border-radius: 20px;
  border: none;
  outline: none;
  margin-top: 10px;
}
</style>
